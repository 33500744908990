export class MySAPCookieAuthResponse {
  constructor(
    public readonly brandId: string,
    public readonly whiteLabelBrandName: string,
    public readonly accessToken: string,
    public readonly locale: string,
    public readonly primaryColor: string,
    public readonly gradientColorHigh: string,
    public readonly gradientColorLow: string,
    public readonly footerPadding: string,
  ) {}
}
