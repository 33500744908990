import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";

import { environment as env } from "../../../../environments/environment";
import { User, UserRole } from "../../models/user";
import { IRequestOptions } from "../http-client";
import {
  StorageKeys,
  LocalStorageService,
  StorageUserItem,
} from "../local-storage.service";
import { IsSSOUserResponse } from "../responses/is-sso-user-response";
import { MySAPCookieAuthResponse } from "../responses/my-sap-cookie-auth-response";
import { SessionStorageService } from "../session-storage.service";
import { AuthTokenService } from "./auth-token.service";
import { BrandService } from "./brand.service";
import { PartnerService } from "./partner.service";

export const isWhiteLabel = () => inject(AuthenticationService).isWhiteLabel();

@Injectable()
export class AuthenticationService {
  private readonly logoutSubject = new Subject<void>();
  private endPointUrl = env.api.baseUrl + env.api.apiPathV1 + "oauth2/token";
  private clientId = env.auth.clientId;
  private options: IRequestOptions = {
    observe: "response",
    responseType: "json",
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };

  constructor(
    private readonly authTokenService: AuthTokenService,
    private readonly brandService: BrandService,
    private readonly http: HttpClient,
    private readonly localStorageService: LocalStorageService,
    private readonly partnerService: PartnerService,
    private readonly router: Router,
    private readonly sessionStorageService: SessionStorageService,
  ) {}

  public get logout$(): Observable<void> {
    return this.logoutSubject.asObservable();
  }

  public isRolePartner(): boolean {
    return this.userRole === UserRole.Partner;
  }

  public isRoleBrand(): boolean {
    return this.userRole === UserRole.Brand;
  }

  public isRoleAdmin(): boolean {
    return this.userRole === UserRole.Admin;
  }

  public isRoleExternalPartnerAdmin(): boolean {
    return this.userRole === UserRole.ExternalPartnerAdmin;
  }

  public isAnyAdmin(): boolean {
    return this.isRoleAdmin() || this.isRoleExternalPartnerAdmin();
  }

  public isRolePartnerOrAdmin(): boolean {
    return (
      this.isRolePartner() ||
      this.isRoleAdmin() ||
      this.isRoleExternalPartnerAdmin()
    );
  }

  public isRoleBrandOrAdmin(): boolean {
    return this.isRoleBrand() || this.isRoleAdmin();
  }

  public isWhiteLabel(): boolean {
    return !!this.whiteLabelBrandId;
  }

  public isRoleBrandOrAdminAsBrand(): boolean {
    return (
      this.isRoleBrand() ||
      (this.isRoleAdmin() && !!this.brandService.lastSelectedBrandId)
    );
  }

  public isRolePartnerOrAdminAsPartner(): boolean {
    return (
      this.isRolePartner() ||
      (this.isAnyAdmin() && !!this.partnerService.lastSelectedPartnerId)
    );
  }

  get whiteLabelBrandId(): number | undefined {
    const id = this.localStorageService.get(StorageKeys.WhiteLabelBrandId);
    return id !== null ? Number(id) : undefined;
  }

  private getUser(): StorageUserItem | undefined {
    // TODO: We should check that what we're storing is actually of type `StorageUserItem`
    return this.localStorageService.getParsed<StorageUserItem>(
      StorageKeys.User,
    );
  }

  public setUser(user: User): void {
    const storedUser = this.getUser();
    const userData: StorageUserItem = {
      createdAt: user.createdAt,
      email: user.email,
      intercomUserHash: user.intercomUserHash,
      isVerified: user.isVerified,
      userId: user.id,
      userRole: user.role,
    };

    if (storedUser) {
      // if local storage user exists, update it
      this.localStorageService.store(
        StorageKeys.User,
        JSON.stringify({
          ...storedUser,
          ...userData,
        }),
      );
    } else {
      // otherwise, create it
      this.localStorageService.store(
        StorageKeys.User,
        JSON.stringify(userData),
      );
    }
  }

  get userId(): number | undefined {
    return this.getUser()?.userId;
  }

  get userCreatedAt(): number | undefined {
    return this.getUser()?.createdAt;
  }

  get userIntercomHash(): string | undefined {
    return this.getUser()?.intercomUserHash;
  }

  get userRole(): string | undefined {
    return this.getUser()?.userRole;
  }

  get isUserVerified(): boolean {
    const user = this.getUser();
    // For now default is true to avoid logged in users seeing the message
    return user?.isVerified !== undefined ? user.isVerified : true;
  }

  get userEmail(): string | undefined {
    return this.getUser()?.email;
  }

  get isUserLogged(): boolean {
    return Boolean(this.getUser()?.token);
  }

  public storeUserSession(
    token: string,
    username: string | undefined = undefined,
  ): void {
    this.authTokenService.userToken = token;
    this.setUserName(username);
  }

  setUserName(userName: string | undefined): void {
    if (userName) {
      this.localStorageService.store(StorageKeys.Username, userName);
    } else {
      this.localStorageService.remove(StorageKeys.Username);
    }
  }

  getUserName(): string | undefined {
    return this.localStorageService.get(StorageKeys.Username) ?? undefined;
  }

  setSSOTemporaryBrandId(brandId: string): void {
    return this.localStorageService.store(
      StorageKeys.SsoTemporaryBrandId,
      brandId,
    );
  }

  getSSOTemporaryBrandId(): string | undefined {
    return (
      this.localStorageService.get(StorageKeys.SsoTemporaryBrandId) ?? undefined
    );
  }

  login(username: string, password: string): Observable<boolean> {
    const payload = {
      grant_type: "password",
      client_id: this.clientId,
      username: username,
      password: password,
    };

    return this.http
      .post<any>(this.endPointUrl, JSON.stringify(payload), this.options)
      .pipe(
        map(({ body }) => {
          // login successful if there's a jwt token in the response
          const token = body && body.access_token;

          if (token) {
            this.storeUserSession(token, username);
            return true;
          }
          return false;
        }),
      );
  }

  public logout(): void {
    this.authTokenService.userToken = undefined;
    this.brandService.clearCurrentBrand();
    this.partnerService.clearCurrentPartner();
    this.localStorageService.clearAll();
    this.sessionStorageService.clearAll();
    this.logoutSubject.next();
  }

  forceLogout(): void {
    this.logout();
    this.router.navigateByUrl("/login");
  }

  isSSoUser(email: string): Observable<IsSSOUserResponse> {
    return this.http
      .get<any>(
        `${env.api.baseUrl}${
          env.api.apiPathV1
        }is_sso_user?email=${encodeURIComponent(email)}`,
        { observe: "response" },
      )
      .pipe(map(({ body }) => new IsSSOUserResponse(body.brandId, body.isSSO)));
  }

  getSSOLoginUrl(brandId: number): Observable<string> {
    return this.http
      .get<any>(
        `${env.api.baseUrl}${env.api.apiPathV1}sso_url?brand_id=${brandId}`,
        {
          observe: "response",
        },
      )
      .pipe(map(({ body }) => body.url));
  }

  getAndValidateSSOAccessToken(
    code: string,
    brandId: number,
  ): Observable<string> {
    return this.http
      .get<any>(
        `${env.api.baseUrl}${env.api.apiPathV1}sso_access_token?code=${code}&brand_id=${brandId}`,
        { observe: "response" },
      )
      .pipe(map(({ body }) => body.accessToken));
  }

  getTokenFromSSOMySAPCookie(
    token: string,
    inviteCampaignName: string,
  ): Observable<MySAPCookieAuthResponse> {
    return this.http
      .get<any>(
        env.api.baseHymerUrl +
          env.api.apiPathV1 +
          "cookie_sso_user_token?token=" +
          token +
          "&inviteCampaignName=" +
          inviteCampaignName,
        { observe: "response" },
      )
      .pipe(
        map(
          ({ body }) =>
            new MySAPCookieAuthResponse(
              body.brandId,
              body.whiteLabelBrandName,
              body.accessToken,
              body.locale,
              body.primaryColor,
              body.gradientColorHigh,
              body.gradientColorLow,
              body.footerPadding,
            ),
        ),
      );
  }
}
